import React from 'react'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { NavLink, useLocation } from "react-router-dom";

function Menu() {
    const location = useLocation();
    const [value, setValue] = React.useState(0);
    React.useEffect(() => {
      // Determine the tab value based on the current location
      if (location.pathname === "/") {
        document.title = "User List";
        setValue(0);
      } else if (location.pathname === "/user/create") {
        document.title = "Create User";
        setValue(1);
      } else if (location.pathname.startsWith("/user/")) {
        document.title = "Edit List";
        setValue(2);
      }
    }, [location]);
    const userName = localStorage.getItem("userName");
    function logout(){
        localStorage.removeItem("userName");
        localStorage.removeItem("expiry");
        document.location="/Login";
    }
    return (
      <>
      <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <Tabs value={value} centered>
          <Tab label="List User" component={NavLink} to="/" />
          <Tab label="Create User" component={NavLink} to="/user/create" />
          <Tab label="Edit User" component={NavLink} to="/user/:id/edit" />
          <Tab label={userName === null || userName === "" ? (window.location = "/Login") : "Logout"} onClick={() => logout()}/>
          <Tab label={`Hello ${userName}...`}/>
        </Tabs>
      </Box>
      </>
    );
}

export default Menu