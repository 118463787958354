import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const crrExpiry = localStorage.getItem("expiry");
const logUser = localStorage.getItem("userName");
crrExpiry <= Date.now() || crrExpiry === null||logUser === null || crrExpiry >= Date.now() + 30 * 60 * 1000? localStorage.removeItem("userName") : toast.success("Login Successful");
const defaultTheme = createTheme();

export default function SignIn() {
    const mbillUrl = 'billing1.geekdialer.com';
    function checkUser(user , password){
        axios.get(`https://${mbillUrl}/magnusAPI/index3.php/userchek/${password}/${user}`).then((response)=>{
            if(response.data){
            console.log(response.data);
            localStorage.setItem("userName", formData.userName);
            localStorage.setItem("expiry", Date.now() + 30 * 60 * 1000);
            toast.success("Login Successfull");
            window.location="/";

        }else{
            toast.error("Login Failed");
            localStorage.removeItem('userName');
        }
        });
    }

  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log({
    //   email: formData.userName,
    //   password: formData.password
    // });
    checkUser(formData.userName , formData.password);
  };
  const [formData, setFormData] = useState({
    userName: '',
    password: ''
})

function handleChange(e){
    setFormData({...formData,[e.target.name]:e.target.value })
}

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="userName"
              label="User Name"
              name="userName"
              autoComplete="userName"
              autoFocus
              onChange={handleChange}
              value={formData.userName}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="password"
              onChange={handleChange}
              value={formData.password}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}