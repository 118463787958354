import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import ListUser from "./components/ListUser";
import ListData from "./billing/ListData";
// import CreateUser from "./components/CreateUser";
import './App.css';
// import EditUser from "./components/EditUser";
import Menu from "./components/Menu";
import MyForm from "./billing/MyForm";
// import DarkExample from "./billing/DarkExample";
import UserDetails from "./billing/UserDetails";
// 
// import Vtiger from "./components/vtiger";
import SignIn from "./components/Login";

function App() {
  return (
    <>
    <ToastContainer/>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
        {/* <Route index path="/" element={<ListUser />} /> */}
        <Route index path="/" element={<> <Menu /> <ListData /> </>} />
        {/* <Route path="user/create" element={<CreateUser />} /> */}
        <Route path="user/create" element={<><Menu /> <MyForm /> </>} />
        {/* <Route path="user/:id/edit" element={<EditUser />} /> */}
        <Route path="user/:id/edit" element={<><Menu /> <UserDetails/> </>} />
        <Route path="Login" element={<SignIn/>} />
      </Routes>
      </BrowserRouter>
    </>
  );
}


export default App;
