import React, { useEffect, useState, useCallback, useRef } from "react";
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import ContentCopyRounded from '@mui/icons-material/ContentCopyRounded';
import SendRounded from '@mui/icons-material/SendRounded';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import axios from "axios";
import Button from "@mui/material/Button";
import { useNavigate, useParams, Link } from "react-router-dom";


function UserDetails() {
  const hasMounted = useRef(false);
  // const mbillUrl = "billing1.geekdialer.com";
  const mbillUrl = "billing1.geekdialer.com";
  const [users, setUsers] = useState([]);
  const [phones, setPhones] = useState([]);
  const [phoneCnt, setPhonesCnt] = useState([]);
  const [did, setDid] = useState([]);
  const [didDestination, setDidDestination] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sipNumber, setSipNumber] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();

  const getUsers = useCallback(async () => {
    await axios.get(`https://${mbillUrl}/magnusAPI/process.php/phone/${id}`).then(function (response) {
      setUsers(response.data[0]);
      setPhones(response.data[1]['rows']);
      setPhonesCnt(response.data[1]['count'] - 1);
      setDid(response.data[2]['rows']);
      setDidDestination(response.data[3]);
    });
  }, [id]);

  useEffect(() => {
    getUsers();
    if (hasMounted.current) {
      console.log(hasMounted.current)
    }
    hasMounted.current = true;
  }, [getUsers]);

  const deleteUser = async (username) => {
    toast.success("User Deleted Successfully...", { position: toast.POSITION.TOP_RIGHT });
    await axios.delete(`https://${mbillUrl}/magnusAPI/process.php/delete/user/${username}`).then(function (response) {
        // console.log(username);
        navigate('/');
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  function getDestinationByIdDid(idDid) {
    const jsonDataArray = Object.values(didDestination);
    const matchedObject = jsonDataArray.find(item => item.id_did === idDid);

    if (matchedObject) {
      return matchedObject.voip_call < 8 ? matchedObject.id_sip : matchedObject.destination;
    } else {
      return "Not Routed";
    }
  }
  async function passChange(option){
    await axios.put(`https://${mbillUrl}/magnusAPI/process.php/change/pass/${option}/${id}`).then(function (response) {
        // console.log(username);
        // navigate('/');
        // console.log(response.data);
        getUsers();
      });

  }
  const handleSipNumber = (event) => {
    // Use regular expressions to allow only numbers
    const input = event.target.value.replace(/\D/g, '');

    setSipNumber(input);
  };
  // const defaulPass = phones[1] && phones[1]['secret']?phones[1]['secret'] : `${users[0]['username']}@${Math.floor(Math.random() * (10000))}@!`;
  const extenChange = async () => {
    const sipNumberInt = parseInt(sipNumber, 10);
    const phoneCntInt = parseInt(phoneCnt, 10);
    // const secret = phones[1]['secret'];
    const secret = phones[1] && phones[1]['secret']?phones[1]['secret'] : `${users[0]['username']}@${Math.floor(Math.random() * (10000))}@!`;
    if (sipNumberInt !== phoneCntInt) {
      if (sipNumberInt < phoneCntInt) {
        const extNum = phoneCntInt - sipNumberInt;
        await axios
          .put(`https://${mbillUrl}/magnusAPI/process.php/exten/delete/${extNum}/${id}/${secret}/${sipNumberInt}`)
          .then(function (response) {
            getUsers();
          });
      } else {
        const extNum = sipNumberInt - phoneCntInt;
        await axios
          .put(`https://${mbillUrl}/magnusAPI/process.php/exten/add/${extNum}/${id}/${secret}/${sipNumberInt}`)
          .then(function (response) {
            getUsers();
          });
      }
    }
  };
  // Enable Recording
  const enableRec = async()=>{
    await axios.put(`https://${mbillUrl}/magnusAPI/process.php/recording/${id}/enable`).then((response)=>{
      toast.success(`${response.data}`);
      getUsers();
    })
  };
  // Delete DID
  async function deleteDid(){
    await axios.delete(`https://${mbillUrl}/magnusAPI/process.php/delete/did/${id}`).then((response)=>{
      toast.success(response.data);
      getUsers();
    })
  }
  function allDetails(retVal) {
    const phoneVal = phones.slice(1).map((val) => `${val.name} ${val.secret}`);
  
    const cdrInfo = `CDR
Username: ${users[0].username}
Password: ${users[0].password}

Extensions
${phoneVal.join('\n')}

Domain: ${mbillUrl}:5060

Zoiper Format
EXTENSION@${mbillUrl}:5060
`;
  // 
  const teleAPI = `
<strong>CDR</strong>
<strong>Username: </strong>${users[0].username}
<strong>Password: </strong>${users[0].password}
<strong>CDR Link: </strong>http://${mbillUrl}/

<strong>Extensions  Password</strong>
${phoneVal.join('\n')}

<strong>Domain: </strong>${mbillUrl}:5060

<strong>Zoiper Format</strong>
${phones[phones.length - 1]['name']}@${mbillUrl}:5060
`;



    return retVal === "clip" ? cdrInfo : teleAPI;
  }
  
  async function sendFormattedTextToTelegram(chatId, formattedText) {
    try {
      const telegramApiToken = '2131726822:AAF4VeDv_7MWGrA4lEngZI81oWX_bn_Gg2A'; // Move token here if needed
  
      await axios.post(`https://api.telegram.org/bot${telegramApiToken}/sendMessage`, {
        chat_id: chatId,
        text: formattedText,
        parse_mode: 'HTML',
      });
  
      console.log('Message sent to Telegram');
    } catch (error) {
      console.error('Error sending message to Telegram');
    }
  }
  
  
  
  
  

  return (
    <>
      <Paper sx={{ width: "90%", justifyContent: "center", alignItems: "center", margin: "0 5%" }}>
        <Paper sx={{ overflow: "hidden" }}>
          <h1>User</h1>
          <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>username</TableCell>
                  <TableCell>password</TableCell>
                  <TableCell>credit</TableCell>
                  <TableCell>calllimit</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(users) && users.length > 0 ? (
                  users.map((user, key) => (
                    <TableRow key={key} hover>
                      <TableCell><Link to={`user/${user.username}/edit`} style={{ marginRight: "10px" }}> {user.id} </Link></TableCell>
                      <TableCell>{user.username}</TableCell>
                      <TableCell>{user.password}</TableCell>
                      <TableCell>{user.credit}</TableCell>
                      <TableCell>{user.calllimit}</TableCell>
                      <TableCell>
                        <Button variant="contained" onClick={() => deleteUser(user.username)}> Delete </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>{users === false ? "No Data Found" : "Loading..."}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <h1>DID</h1>
          <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>DID</TableCell>
                  <TableCell>Assigned</TableCell>
                  <TableCell>Recording</TableCell>
                  <TableCell>Route</TableCell>
                  <TableCell>Uses</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(did) && did.length > 0 ? (
                  did.map((dids, key) => (
                    <TableRow key={key} hover>
                      <TableCell>
                      <Link
                          to={`user/${dids.id}/edit`}
                          style={{ marginRight: "10px" }}
                        >
                          {key + 1}
                        </Link>
                      </TableCell>
                      <TableCell>{dids.did}</TableCell>
                      <TableCell>{dids.reserved}</TableCell>
                      <TableCell>{dids.record_call}</TableCell>
                      <TableCell>{getDestinationByIdDid(dids.id)}</TableCell>
                      <TableCell>{dids.secondusedreal}</TableCell>
                      <TableCell>
                        
                        <Button
                          variant="contained"
                          onClick={() => deleteDid()}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>{did === false ? "No Data Found" : "Loading..."}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <h1>Phones</h1>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer component={Paper} sx={{ maxHeight: 600 }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Extension</TableCell>
                  <TableCell>Password</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(phones) && phones.length > 0 ? (
                  phones.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((phone, key) => (
                    <TableRow key={key} hover>
                      <TableCell><Link to={`user/${phone.id}/edit`} style={{ marginRight: "10px" }}> {key + 1} </Link></TableCell>
                      <TableCell>{phone.lineStatus}</TableCell>
                      <TableCell>{phone.name}</TableCell>
                      <TableCell>{phone.secret}</TableCell>
                      <TableCell>{phone.accountcode}</TableCell>
                      <TableCell>
                        <Button variant="contained" onClick={() => deleteUser(phone.id)}> Delete </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>Loading...</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={phones.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <div style={{display: "grid",gridTemplateColumns: "50% 1fr", gap: "10px",justifyContent: "space-around",alignItems: "center",padding: "10px 0 10px 0",}}>

        <Button variant="contained" size="large" onClick={()=> passChange("cdrPhone")}> Change CDR & Phone pass </Button>
        <Button variant="contained" size="large" onClick={()=> passChange("cdr")}> Change CDR Pass</Button>
        <Button variant="contained" size="large" onClick={()=> passChange("phone")}> Change Phone Pass </Button>
        <Button variant="contained" size="large" onClick={()=>{enableRec()}}> Enable Recording </Button>
        <Button variant="contained" size="large" disabled = {sipNumber === '' ? true : false} onClick={() =>{extenChange()}} > Change Extension </Button>
        <TextField label="Enter a Number" variant="outlined"  value={sipNumber} onChange={handleSipNumber}/>
        <Button variant="contained" size="large" onClick={() => navigator.clipboard.writeText(allDetails("clip"))}> Copy Details  <ContentCopyRounded /> </Button>
        <Button variant="contained" size="large" onClick={() => sendFormattedTextToTelegram(-1001715803652, allDetails("telegram"))}> Send Details <SendRounded /> </Button>
        </div>
      </Paper>
    </>
  );
}

export default UserDetails;
